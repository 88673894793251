/* eslint-disable no-trailing-spaces */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import {
    DTO, DTORef, DTOMetaData, DTOMethods, DTOClassMethods,
    BoundEndpointAPI, Attachment
} from '../dto';
import { Networkzone_v1 } from './networkzone-v1';

export interface ObservabilityAllocation_v1 extends DTO, DTOMethods<ObservabilityAllocation_v1> {
    application: DTORef;
    footprint: DTORef;
    networkzone: Networkzone_v1;
    logicalEnvironment: DTORef;
    dynatraceEnvironment: DTORef;
    coverageObjective: DTORef;
    state: string;
    disposition: string;
    startDate: Date;
    endDate: Date;
    rateGroup: DTORef;
    hostDailyCount: number;
    dailyFullstack: number;
    dailyInfra: number;
    dailyAppSec: number;
    dailyDem: number;
    dailyDdu: number;
    dailyZos: number;
    dailyGrail: number;
    estDailyChargeback: number;
    resolvedUsers: Attachment[];
}
export const ObservabilityAllocation_v1: DTOMetaData & DTOClassMethods<ObservabilityAllocation_v1> = {
    _label:'Observability Allocation',
    _dto: 'ObservabilityAllocation_v1',
    _type: 'observability-allocation',
    _colors: { primaryThemeColor: "#4a89d2", secondaryThemeColor: "#446c9b", tertiaryThemeColor: "#446c9b" },
    _keysets: ["id"], // TODO: Generate from DTO Schema
    _properties: [
        { label: 'Application', prop: 'application', hint: 'D t o ref', type: 'dto', fixed: false, source: undefined, values: undefined },
        { label: 'Footprint', prop: 'footprint', hint: 'Footprint', type: 'dto', fixed: true, source: '/footprint/v1.0', values: undefined },
        { label: 'Networkzone', prop: 'networkzone', hint: 'Networkzone', type: 'dto', fixed: true, source: '/networkzone/v1.0', values: undefined },
        { label: 'Logical environment', prop: 'logicalEnvironment', hint: 'Logical environment', type: 'dto', fixed: true, source: '/logical-environment/v1.0', values: undefined },
        { label: 'Dynatrace environment', prop: 'dynatraceEnvironment', hint: 'Dynatrace environment', type: 'dto', fixed: true, source: '/dynatrace-environment/v1.0', values: undefined },
        { label: 'Coverage objective', prop: 'coverageObjective', hint: 'D t o ref', type: 'dto', fixed: false, source: undefined, values: undefined },
        { label: 'State', prop: 'state', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Disposition', prop: 'disposition', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Start date', prop: 'startDate', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined },
        { label: 'End date', prop: 'endDate', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined },
        { label: 'Rate group', prop: 'rateGroup', hint: 'Rategroup', type: 'dto', fixed: true, source: '/rategroup/v1.0', values: undefined },
        { label: 'Host daily count', prop: 'hostDailyCount', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Daily fullstack', prop: 'dailyFullstack', hint: 'Decimal value (-10^255+1 .. 10^255-1, 2 decimals)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Daily infra', prop: 'dailyInfra', hint: 'Decimal value (-10^255+1 .. 10^255-1, 2 decimals)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Daily app sec', prop: 'dailyAppSec', hint: 'Decimal value (-10^255+1 .. 10^255-1, 2 decimals)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Daily dem', prop: 'dailyDem', hint: 'Decimal value (-10^255+1 .. 10^255-1, 2 decimals)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Daily ddu', prop: 'dailyDdu', hint: 'Decimal value (-10^255+1 .. 10^255-1, 2 decimals)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Daily zos', prop: 'dailyZos', hint: 'Decimal value (-10^255+1 .. 10^255-1, 2 decimals)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Daily grail', prop: 'dailyGrail', hint: 'Decimal value (-10^255+1 .. 10^255-1, 2 decimals)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Est daily chargeback', prop: 'estDailyChargeback', hint: 'Decimal value (-10^255+1 .. 10^255-1, 2 decimals)', type: 'number', fixed: true, source: undefined, values: undefined },
     ],
    _related: [
        { prop: 'resolvedUsers', type: 'unknown', class: 'Unknown', label: 'UsersWithFullyResolvedAccessLevel' },
     ],
    _endpoint: '/api/eob/v1.0/observability-allocation/v1.0',
    _docLink: '/wiki/elevate/Assets/observability-allocation/',

    endpointAPI: new BoundEndpointAPI<ObservabilityAllocation_v1>('ObservabilityAllocation_v1', DTO),
    from: (obj: any): ObservabilityAllocation_v1 => {
        return Object.setPrototypeOf(obj, ObservabilityAllocation_v1._prototype);
    },
    select: (p: any = ''): any => {
        return ObservabilityAllocation_v1.endpointAPI.get(p).then(res =>
            !res ? undefined
            : Array.isArray(res) ? res.map(a => ObservabilityAllocation_v1.from(a) as ObservabilityAllocation_v1)
            : ObservabilityAllocation_v1.from(res)
    )}
}
